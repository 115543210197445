#root, .max-height {
    height: 100%;
}

.site-layout-content, .ant-space, .ant-space-item{
    height: 100%;
}

.site-layout-content{
    padding-bottom: 4.7rem;
}

.my-app-header{
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}