.detail-summary-cell{
    background-color: #e9e9e9;
    font-weight: bold;
}

.detail-summary-cell-rest{
    background-color: #e9e9e9;
    font-weight: bold;
    z-index: -100;
}

.detail-summary-cell-rest2{
    /* font-size: 1rem; */
    background-color: #e9e9e9;
    font-weight: 400;
    z-index: -100;
}