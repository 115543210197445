@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.Spin-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Main {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  /* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
  height: 48px;
  line-height: 48px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* AppLayout.jsx */
#collapsed-btn:hover{
    background: #32699b;
}

#collapsed-btn{
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    width: 4rem;
    font-size: 1.3rem;
    background: #12304d;

}
